import { Box, Text, Link } from 'rebass';
import { Icon, Footnote } from "../../../../src/components";
import * as React from 'react';
export default {
  Box,
  Text,
  Link,
  Icon,
  Footnote,
  React
};