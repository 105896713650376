import { Box, Text, Link } from 'rebass';
import { Icon, Footnote } from "../../../../src/components";
import OrigamiFoxFilled from "../../../../src/images/origami-fox-filled.svg";
import OrigamiFoxOutline from "../../../../src/images/origami-fox-outline.svg";
import * as React from 'react';
export default {
  Box,
  Text,
  Link,
  Icon,
  Footnote,
  OrigamiFoxFilled,
  OrigamiFoxOutline,
  React
};